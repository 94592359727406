import React from "react"

import Layout from "../components/Layout"
import Seo from "../components/Seo"
import Hero from "../components/homepage/Hero";
import {graphql, useStaticQuery} from "gatsby";
import Highlights from "../components/homepage/Highlights";
import CaseStudies from "../components/homepage/CaseStudies";
import VideoSection from "../components/VideoSection";
import Posts from "../components/homepage/Posts";
import Save from "../icons/save.svg";
import Code from "../icons/code.svg";
import Responsive from "../icons/responsive.svg";
import {getImage} from "gatsby-plugin-image";
import BuyersGuideLink from "../components/BuyersGuideLink";


export default function Catalog() {

  const data = useStaticQuery(graphql`{
  homehero: file(absolutePath: {regex: "/catalog-hero.jpg/"}) {
    childImageSharp {
      gatsbyImageData(width: 650, layout: CONSTRAINED)
    }
  }
}
`);


  return (
    <Layout>


      <Hero
        title={'Course Catalog Software'}
        text={'Convert your PDF into an easy-to-manage digital catalog with our best-of-class course catalog software.'}
        linkText={'Get Started'}
        linkPath={'/start/'}
        buttonStyle={'button'}
        imgSrc={getImage(data.homehero)}
        imgAlt={'Student using course catalog software on a laptop.'}
      />
      <Highlights
        title={'Upgrade with Us'}
        text={'Trade in your 200+ page PDFs for a modern, elegant site that students and potential students can navigate on any device.'}
        highlightOneIcon={<Save/>}
        highlightOneTitle={'We’re Affordable'}
        highlightOneText={'We\'re typically the most affordable option for course catalog software, and you don\'t pay an additional start-up fee the first year.'}
        highlightTwoIcon={<Code/>}
        highlightTwoTitle={'No Development'}
        highlightTwoText={'We do the initial import of all your catalog content, and we’ll work with you during all your content updates.'}
        highlightThreeIcon={<Responsive/>}
        highlightThreeTitle={'Customizable & Flexible'}
        highlightThreeText={'Fully customizable designs and layouts, and workflow capabilities that allow you to set granular permissions and approval processes before content goes live.'}
      />
      <CaseStudies/>
      <VideoSection
        title={'Make Your Course Catalog Accessible'}
        text={'Learn how Clean Catalog makes your course catalog accessible and easy to navigate for all users.'}
        vimeoid={'473232701'}
      />
      <BuyersGuideLink/>
      <Posts
        subjectTitle={'Course Catalog'}
        tag={'course catalog software'}
      />


    </Layout>
  );

}

export function Head(props) {
  return (
    <Seo
      title="Course Catalog Software"
      description='Course catalog software built for colleges and universities — Clean Catalog.'
      canonical={props.location.pathname}
    />
  )
}



