import React from "react"
import styled from "styled-components";
import Button from "./Button";


const Wrapper= styled.div`
  padding-top: 60px;
  padding-bottom: 60px;
  text-align: center;
  background-color: ${props => props.theme.gray};
  h2 {
    margin-top: 0;
  }
  a {
    margin: 0 auto;
  }
  p {
    font-size: ${props => props.theme.fontMdSm};
  }
 `;

const BuyersGuideLink = (props) => {
  return (
    <Wrapper>

      <h2>Want to Learn How to Get Started?</h2>
      <Button
        path={'/buyers-guide/'}
        text={'Buyer\'s Guide'}
      />
      <p>Check out our Buyer's Guide for comprehensive information on getting started with Clean Catalog.</p>

    </Wrapper>
  )
}

export default BuyersGuideLink;
